import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";

export function useRecaptchaKey() {
  const [recaptchaKey, setRecaptchaKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRecaptchaKey = async () => {
      try {
        setLoading(true);
        const {
          data: { recaptchaKey }
        } = await axios.get("/auth/recaptcha-key");
        setRecaptchaKey(recaptchaKey);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError((error as AxiosError).message);
        } else {
          setError("An unexpected error occurred.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchRecaptchaKey();
  }, []);

  return { loading, error, recaptchaKey };
}
