import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import AuthenticationTemplate from "suited/components/AuthenticationTemplate/AuthenticationTemplate";

import { IIdentityParams, IUTMParams } from "./UserRegistration.types";
import RegistrationForm from "./components/RegistrationForm";
import { useRecaptchaKey } from "./components/RegistrationForm/RegistrationForm.hooks";

export interface IUserRegistrationProps {
  industryContext: string;
  autopilotSessionId: string;
  formKey: string;
  invitationCode: string;
  setAuthToken: (token: any) => void;
  utm: IUTMParams;
  identity: IIdentityParams;
}

const UserRegistration = (props: IUserRegistrationProps) => {
  const navigate = useNavigate();
  const { recaptchaKey, loading } = useRecaptchaKey();
  const [redirect, setRedirect] = useState(false);

  const handleClickSignIn = () => {
    setRedirect(true);
  };

  if (redirect) {
    if (props.invitationCode) {
      navigate({ to: `/login?code=${props.invitationCode}&utm_source=direct_invitation` });
    } else {
      navigate({ to: "/login" });
    }
  }

  const RegistrationComponent = () => {
    return (
      <AuthenticationTemplate
        headline="Sign Up for Free"
        ctaText="Sign In"
        ctaOnClick={handleClickSignIn}
        registrationPage
      >
        <RegistrationForm {...props} />
      </AuthenticationTemplate>
    );
  };

  // NOTE: Prevent recaptcha key from rendering with null value while loading.
  if (loading) {
    return <RegistrationComponent />;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey} useEnterprise>
      <RegistrationComponent />
    </GoogleReCaptchaProvider>
  );
};

export default UserRegistration;
